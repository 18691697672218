<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 51.04 66.68">
    <g id="Calque_2"
       data-name="Calque 2">
      <g id="grouper">
        <circle class="cls-1"
                cx="24.93"
                cy="11.55"
                r="9.55" />
        <polygon class="cls-1"
                 points="47.61 23.78 25.52 62.63 3.44 23.78 47.61 23.78" />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: none;
  stroke: #292828;
  stroke-miterlimit: 10;
  stroke-width: 4px;
}
</style>
